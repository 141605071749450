import { Suspense, useEffect, useState } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { UIThemeProvider, SessionChecker, Main, UISpinner } from './component'
import * as constants from './constants'
import UDL from './component/UDL/UDL'
/*
  NOTE: If we `import { Username } from './pages` we cannot mock it at module
  level. Specific import are needed to allow mock:
  `import Username from './pages/Username'`
*/
import SignOut from './pages/SignOut'
import AddEmail from './pages/AddEmail'
import ChangePassword from './pages/ChangePassword'
import CheckUsername from './pages/CheckUsername'
import CodeSend from './pages/MultiFactorAuthentication/CodeSend'
import CompleteAccount from './pages/CompleteAccount'
import ConsentManagement from './pages/ConsentManagement'
import CookieEnablement from './pages/CookieEnablement'
import SessionInfo from './pages/SessionInfo'
import EditEmail from './pages/EditEmail'
import EditMobile from './pages/EditMobile'
import EmailVerification from './pages/EmailVerification'
import Failure from './pages/Failure'
import MobileCodeSend from './pages/MobileCodeSend'
import MultiFactorAuthentication from './pages/MultiFactorAuthentication'
import Password from './pages/Password'
import EmailOrPhoneAssociated from './pages/ForgotPassword/EmailOrPhoneAssociated'
import PasswordReset from './pages/ForgotPassword/PasswordReset'
import PasswordResetSuccess from './pages/PasswordResetSuccess'
import PhoneVerification from './pages/PhoneVerification'
import RecoveryVerification from './pages/MultiFactorAuthentication/RecoveryVerification'
import Redirect from './pages/Redirect'
import RedirectIDP from './pages/RedirectIDP'
import SendVerificationCode from './pages/SendVerificationCode'
import SignUp from './pages/SignUp'
import SocialLogin from './pages/SocialLogin'
import TnCPage from './pages/TermsOfService/TnCPage'
import Unverified from './pages/MultiFactorAuthentication/Unverified'
import Username from './pages/Username'
import UsernameRecovery from './pages/UsernameRecovery'
import UsernameRecoverySuccess from './pages/UsernameRecoverySuccess'
import Verification from './pages/Verification'
import VerificationConfirmed from './pages/VerificationConfirmed'
import VerificationError from './pages/VerificationError'
import VerificationSuccess from './pages/VerificationSuccess'
import OOBESignUp from './pages/OOBESignUp'
import TnCManagement from './pages/TermsOfService/TnCManagement'
import LearnMore from './pages/LearnMore'
import LeakedCredsChangePassword from './pages/LeakedCredsChangePassword'
import OTPSignIn from './pages/OTPSignIn'
import { css, Global } from '@emotion/react'
import tokens from '@veneer/tokens/dist/tokens'
import useWindowSize from './customHooks/useWindowSize'
import { State } from './common/types'

import { getMultipleFeatureFlagsFromLaunchDarkly } from './utils/featureFlags'
import { runOptimizely } from './utils/optimizely'

import './utils/rum.js'
import './App.scss'
import useDirection from './customHooks/useDirection'

const { layout } = tokens
const { smMin } = layout

const App = () => {
  const { isRTL } = useDirection()
  const [width] = useWindowSize()
  const minSize = parseInt(smMin.substring(0, 3))
  const [backgroundColor, setBackgroundColor] = useState('#d3d3d3')
  const { client, sessionId } = useSelector((state: State) => state.session)
  const { locale } = useSelector((state: State) => state.user)
  const { clientId } = client
  const theme = useSelector((state: State) => state.session[constants.PARAM_THEME_ID])
  
  useEffect(() => {
    const handleFeatureFlags = async () => {
      const { LAUNCH_DARKLY_FLAGS } = constants
      const LAUNCH_DARKLY_FLAGS_TO_GET = [LAUNCH_DARKLY_FLAGS.OPTIMIZELY]

      const featureFlagObj = await getMultipleFeatureFlagsFromLaunchDarkly(
        sessionId,
        clientId,
        LAUNCH_DARKLY_FLAGS_TO_GET
      )
      if (featureFlagObj[LAUNCH_DARKLY_FLAGS.OPTIMIZELY]) {
        window.hpIdParams = { locale, client: clientId }
        runOptimizely()
      }
    }
    handleFeatureFlags()
  }, [clientId, sessionId, locale])

  useEffect(() => {
    if (width === 0) return

    if (width <= minSize) {
      setBackgroundColor('#ffffff !important')
      return
    }
  }, [width, minSize])

  useEffect(() => {
    if (theme === constants.THEMES.HPAPP) {
      return setBackgroundColor('#E0E1EE !important')
    }
    setBackgroundColor('#d3d3d3')
  }, [theme])

  return (
    <>
      <Global
        styles={css`
          body {
            background-color: ${backgroundColor};
          }
          ${isRTL &&
          css`
            label[id$='-label'] {
              right: 24px;
            }
            span.label.vn-checkbox__span--text {
              margin-left: 0;
              margin-right: 12px;
            }
            p[class$='-FormHelperText'] {
              span.caption.text {
                padding: 4px;
              }
            }
          `}
        `}
      />
      <SessionChecker>
        <UIThemeProvider>
          <UDL>
            <Suspense fallback={<UISpinner />}>
              <Routes>
                <Route path={constants.PAGE_ERROR} element={<Failure />} />
                <Route path={constants.PAGE_SIGNOUT} element={<SignOut />} />
                <Route path={constants.PAGE_VERIFICATION} element={<Verification />} />
                <Route path={constants.PAGE_VERIFICATION_CONFIRMED} element={<VerificationConfirmed />} />
                <Route path={constants.PAGE_VERIFICATION_ERROR} element={<VerificationError />} />
                <Route path={constants.PAGE_COOKIE_ENABLEMENT} element={<CookieEnablement />} />
                <Route path={constants.PAGE_SESSION_INFO} element={<SessionInfo />} />
                <Route path="/" element={<Main />}>
                  <Route index element={<Navigate to={constants.PAGE_USERNAME} replace />} />
                  <Route path={constants.PAGE_USERNAME} element={<Username />} />
                  <Route path={constants.PAGE_CHECK_USERNAME} element={<CheckUsername />} />
                  <Route path={constants.PAGE_REDIRECT_TO} element={<RedirectIDP />} />
                  <Route path={constants.PAGE_PASSWORD} element={<Password />} />
                  <Route path={constants.PAGE_CHANGE_PASSWORD} element={<ChangePassword />} />
                  <Route path={constants.PAGE_SIGN_UP} element={<SignUp />} />
                  <Route path={constants.PAGE_EMAIL_VERIFICATION} element={<EmailVerification />} />
                  <Route path={constants.PAGE_VERIFICATION_SUCCESS} element={<VerificationSuccess />} />
                  <Route path={constants.PAGE_CODE_SEND} element={<SendVerificationCode />} />
                  <Route path={constants.PAGE_MOBILE_CODE_SEND} element={<MobileCodeSend />} />
                  <Route path={constants.PAGE_PASSWORD_RESET} element={<PasswordReset />} />
                  <Route
                    path={constants.PAGE_LEAKED_CREDENTIALS_CHANGE_PASSWORD}
                    element={<LeakedCredsChangePassword />}
                  />
                  <Route path={constants.PAGE_PASSWORD_RESET_SUCCESS} element={<PasswordResetSuccess />} />
                  <Route path={constants.PAGE_PHONE_VERIFICATION} element={<PhoneVerification />} />
                  <Route path={constants.PAGE_CONSENT_MANAGEMENT} element={<ConsentManagement />} />
                  <Route path={constants.PAGE_USERNAME_RECOVERY_SUCCESS} element={<UsernameRecoverySuccess />} />
                  <Route path={constants.PAGE_TERMS_AND_CONDITIONS} element={<TnCPage />} />
                  <Route path={constants.PAGE_SOCIAL_LOGIN} element={<SocialLogin />} />
                  <Route path={constants.PAGE_REDIRECT} element={<Redirect />} />
                  <Route path={constants.PAGE_EDIT_EMAIL} element={<EditEmail />} />
                  <Route path={constants.PAGE_EDIT_MOBILE} element={<EditMobile />} />
                  <Route path={constants.PAGE_AUTH} element={<MultiFactorAuthentication />} />
                  <Route path={constants.PAGE_AUTH_CODE_SEND} element={<CodeSend />} />
                  <Route path={constants.PAGE_AUTH_UNVERIFIED} element={<Unverified />} />
                  <Route path={constants.PAGE_AUTH_RECOVERY_VERIFICATION} element={<RecoveryVerification />} />
                  <Route path={constants.PAGE_ADD_EMAIL} element={<AddEmail />} />
                  <Route path={constants.PAGE_USERNAME_RECOVERY} element={<UsernameRecovery />} />
                  <Route path={constants.PAGE_EMAIL_OR_PHONE_ASSOCIATED} element={<EmailOrPhoneAssociated />} />
                  <Route path={constants.PAGE_COMPLETE_ACCOUNT} element={<CompleteAccount />} />
                  <Route path={constants.PAGE_OOBE_SIGN_UP} element={<OOBESignUp />} />
                  <Route path={constants.PAGE_TNC_MANAGEMENT} element={<TnCManagement />} />
                  <Route path={constants.PAGE_LEARN_MORE} element={<LearnMore />} />
                  <Route path={constants.PAGE_OTP_SIGN_IN} element={<OTPSignIn />} />
                </Route>
              </Routes>
            </Suspense>
          </UDL>
        </UIThemeProvider>
      </SessionChecker>
    </>
  )
}

export default App
