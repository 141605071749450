import { initializeLaunchDarkly, getFeatureFlag } from './launchdarkly.js'

export async function getFeatureFlagFromLaunchDarkly(session, client, featureName) {
  if (!session || !client) return false
  try {
    // Ensure LaunchDarkly is properly initialized
    await initializeLaunchDarkly(session, client)

    const isActive = await getFeatureFlag(featureName)
    return isActive
  } catch (error) {
    console.error('Failed to initialize LaunchDarkly:', error)
    return false
  }
}

export async function getMultipleFeatureFlagsFromLaunchDarkly(session, client, featureNames) {
  if (!session || !client) return {}

  try {
    // Ensure LaunchDarkly is properly initialized
    await initializeLaunchDarkly(session, client)

    const resolvedFeatureFlags = {}
    for (const featureFlag of featureNames) {
      const isActive = await getFeatureFlag(featureFlag)
      resolvedFeatureFlags[featureFlag] = isActive
    }

    return resolvedFeatureFlags
  } catch (error) {
    console.error('Failed to initialize LaunchDarkly:', error)
    return {}
  }
}
