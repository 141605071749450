import Card from '@veneer/core/dist/esm/scripts/card'
import Scrollbar from '@veneer/core/dist/esm/scripts/scrollbar'
import styled from '@emotion/styled'
import tokens from '@veneer/tokens/dist/tokens';

const { layout } = tokens
const { smMin } = layout

const xMin = '661px';
const height = 754;
const width = 480;
const paddingT = 38;
const paddingR = 48;
const paddingB = 38;
const paddingL = 48;
const mobilePadding = 20;
const halfW = Math.round((width - paddingR - paddingB) / 2);
const halfH = Math.round((height - paddingT - paddingB) / 2);

interface MainLayoutProps {
  hasScrollbar?: boolean
  isMobile?: boolean
  adjustHeight?: string;
}

export const MainLayout = styled(Card)<MainLayoutProps>`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  padding-top: calc(max(${mobilePadding}px, 50vh - ${halfH}px));
  padding-bottom: calc(max(${mobilePadding}px, 50vh - ${halfH}px));
  padding-left: calc(max(${mobilePadding}px, 50vw - ${halfW}px));
  padding-right: calc(max(${mobilePadding}px, 50vw - ${halfW}px));
  @media all and (min-height: ${height}px) {
    height: ${height}px;
    padding-top: ${paddingT}px;
    padding-bottom: ${paddingB}px;
  }
  @media all and (min-width: ${smMin}) {
    width: ${width}px;
    padding-left: ${paddingL}px;
    padding-right: ${paddingR}px;
  }
  @media all and (max-height: ${height}px) and (max-width: ${xMin}) {
    width: 100%;
    border-radius: 0;
    padding-left: calc(max(${mobilePadding}px, 50vw - ${halfW}px));
    // padding-right: 133px; /* fallback for HP SMART */
    padding-right: calc(max(${mobilePadding}px, 50vw - ${halfW}px));
    height: ${props => (props.adjustHeight && props.adjustHeight)};
  }
`
// The max-height should be the height of the layout - the padding on the top and bottom
export const Content = styled(Scrollbar)<MainLayoutProps>`
  max-height: calc(${height}px - ${paddingT + paddingB}px);
  flex: 1;
  display: flex;
  padding-right: ${props => (props.hasScrollbar ? '9px' : '17px')};
  margin-right: -17px;
  padding-left: 5px;
  margin-left: -5px;
  flex-direction: column;
  overflow-x: hidden;

  //should solve Internet Explorer/Jarvis issues
  @media all and (max-height: ${height}px) and (max-width: ${xMin}) {
    max-height:900px;
  }
`

export const PageContent = styled.div`
  flex: 1;
`
