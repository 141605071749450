import config from '../config.js'

export async function runOptimizely() {
  try {
    await new Promise((resolve, reject) => {
      const script = document.createElement('script')
      script.addEventListener('load', resolve)
      script.addEventListener('error', reject)
      script.src = config.optimizely.url
      script.async = true
      document.head.appendChild(script)
    })
    return
  } catch (error) {
    console.error('Failed to initialize Optimizely:', error)
  }
}
